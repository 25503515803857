.upcoming-events {
  padding: 45px 0;

  text-align: center;

  background-color: rgb(197,225,252);

  @include filter-gradient(#c5e1fc, #ffffff, vertical);
  @include background-image(linear-gradient(top,  rgba(197,225,252,1) 0%,rgba(255,255,255,1) 100%));
  &.community {
    background: #ebf0e0;
  }
  .events-links {
    text-align: right;

    color: #333333;

    font-size: 20px;
    @media (max-width:950px) {
      font-size: 16px;
    }
    @media (max-width:750px) {
      text-align: center;
    }
    a {
      margin: 0 5px;

      text-decoration: none;

      color: #333333;

      @include transition($transition-default);
      &:hover {
        color: #6095cb;
      }
    }
  }

  .events-list {
    overflow: hidden;

    margin: 0 -20px 30px;
    @media (max-width:1000px) {
      margin: 0 -5px 30px;
    }
    .item {
      float: left;

      width: (100%/3);
      margin-top: 50px;
      padding: 0 20px;
      @media (max-width:1000px) {
        padding: 0 5px;
      }
      @media (max-width:750px) {
        float: none;

        width: 100%;
      }
      .inside {
        position: relative;

        display: block;

        text-align: left;
        text-decoration: none;

        border: 10px solid #6095cb;
        background: #ffffff;
        .date {
          position: absolute;
          top: -40px;
          left: 15px;

          padding: 10px 15px;

          text-align: center;
          text-transform: uppercase;

          color: #e4f4fc;
          background: $green;

          font-size: 19px;
          font-weight: 700;
          line-height: 1;
          span {
            display: block;

            font-size: 50px;
          }
        }
        .img-block {
          text-align: center;
          img {
            max-width: 100%;
          }
        }
        .content-block {
          padding: 10px 35px 15px;
          @media (max-width:1000px) {
            padding: 10px 10px 15px;
          }
          .time {
            margin-bottom: 5px;

            text-transform: uppercase;

            color: #333333;

            font-size: 12px;
            font-weight: 700;
          }
          .name {
            display: table;

            width: 100%;

            text-transform: uppercase;

            color: #333333;

            font-size: 19px;
            font-weight: 700;
            .td {
              display: table-cell;

              vertical-align: middle;
            }

          }
          .read-more {
            text-transform: uppercase;

            color: #838384;

            font-size: 13px;
            &:before {
              display: block;

              width: 40px;
              height: 2px;
              margin: 15px 0 7px;

              content: "";

              background: #39a2ca;
            }
            i {
              position: relative;
              left: 0;

              @include transition($transition-default);
            }
          }
        }
        &:hover {
          .read-more i {
            left: 5px;
          }
        }
      }
    }
  }

  .more {
    margin: 30px auto 0;

    color: #fff;
    background: #6095cb;
  }
}
