.newsland-events-links {
  padding: 25px 10px;

  text-align: center;

  background: #ebf0e0;
  a {
    width: 480px;
    margin: 0 25px 25px;
    padding: 10px 0;

    text-decoration: none;

    border: 10px solid #819066;
    background: #56623f;

    @include inline-block;
    @include transition($transition-default);
    @media (max-width:1100px) {
      width: 380px;
    }
    @media (max-width:900px) {
      width: 350px;
      margin: 0 10px 25px;
    }
    @media (max-width:770px) {
      width: 100%;
      margin: 0 0 10px;
      padding: 5px 0;
    }
    .icon {
      width: 75px;
      height: 75px;
      margin: 0 auto;

      @include transition($transition-default);
      @include inline-block;
    }
    .text {
      text-transform: uppercase;

      color: #ffffff;

      font-size: 30px;

      @include inline-block;
      @include transition($transition-default);
    }

    &:nth-child(1) {
      .icon {
        background: url($image+"four-icon-1.png");
      }
    }
    &:nth-child(2) {
      .icon {
        background: url($image+"four-icon-2.png");
      }
    }
    &:hover {
      border-color: #56623f;
      background: #85946a;
      .icon {
        background-position: bottom center;
      }
      .text {
        color: #343435;
      }
    }
    &.active {
      position: relative;

      border-color: #56623f;
      background: #85946a;
      .icon {
        background-position: bottom center;
      }
      .text {
        color: #343435;
      }
      &:after {
        position: absolute;
        bottom: -35px;
        left: 30px;

        display: block;

        width: 0;
        height: 0;

        content: "";

        border-width: 25px 15px 0 15px;
        border-style: solid;
        border-color: #56623f transparent transparent transparent;
        @media (max-width:770px) {
          display: none;
        }
      }
    }
  }
}
