.municipal-village {
  display: table;

  width: 100%;
  @media (max-width:960px) {
    display: block;
  }
  .column {
    position: relative;

    display: table-cell;

    width: 50%;
    @media (max-width:960px) {
      display: block;
      width: 100%;
    }
    .icon-2 {
      position: absolute;
      z-index: 1;
      top: 55px;
      right: -43px;

      display: table;

      width: 85px;
      height: 85px;

      text-align: center;

      border: 1px solid #f7f4ea;
      background: #7c8c5f;

      @include transform(rotate(45deg));
      @media (max-width:960px) {
        display: none;
      }
      .inside {
        display: table-cell;

        width: 85px;
        height: 85px;

        vertical-align: middle;

        @include transform(rotate(-45deg));
        span {
          display: block;

          text-transform: uppercase;

          color: #b2bd9f;

          font-size: 12px;
          font-weight: 700;
        }
        &:before {
          position: absolute;
          top: 50%;
          left: -180px;

          display: block;

          width: 150px;
          height: 1px;

          content: "";

          background: #fff;
          @media (max-width:1600px) {
            display: none;
          }
        }
        &:after {
          position: absolute;
          top: 50%;
          right: -180px;

          display: block;

          width: 150px;
          height: 1px;

          content: "";

          background: #fff;
          @media (max-width:1600px) {
            display: none;
          }
        }
      }
    }
    .inside-block {
      max-width: 80%;

      text-align: center;
      @media (max-width:1920px) {
        max-width: 750px;
      }
      @media (max-width:960px) {
        max-width: 100%;
      }
    }
    .title {
      margin-bottom: 30px;

      text-transform: uppercase;

      font-size: 28px;
      font-weight: 700;
      @media (max-width:500px) {
        font-size: 23px;
      }
    }
    p {
      color: #3a3333;

      font-size: 21px;
      @media (max-width:500px) {
        font-size: 16px;
      }
    }
    .name {
      text-transform: uppercase;

      color: #3d3d3d;

      font-size: 18px;
      font-weight: 700;
      @media (max-width:960px) {
        color: #ffffff;
      }
    }
    .job {
      color: #ffffff;

      font-size: 16px;
    }
    .more {
      margin: 20px auto;

      color: #b2bd9f;
      background: #455a21;
    }
    .list {
      font-size: 0;
      .item {
        display: inline-block;

        width: 50%;
        padding: 0 15px 80px;

        vertical-align: top;
        text-transform: uppercase;

        font-size: 25px;
        line-height: 1;
        @media (max-width:1200px) {
          font-size: 17px;
        }
        @media (max-width:1000px) {
          padding: 0 10px 30px;
        }
        @media (max-width:500px) {
          width: 100%;
        }
        a {
          text-decoration: none;

          color: #aed363;

          @include transition($transition-default);
          .icon {
            width: 80px;
            height: 65px;
            margin: 0 auto 5px;

            @include transition($transition-default);
          }
          &:hover {
            color: #fff;
            .icon {
              background-position: bottom center;
            }
          }
        }
      }
    }
    &:nth-child(1) {
      background: url($image+"municipal-village-bg-1.jpg") center center;
      background-size: cover;
      .inside-block {
        padding: 80px 80px 80px 10px;
        margin: 0 0 0 auto;
        @media (max-width:1200px) {
          padding: 80px 60px 80px 10px;
        }
        @media (max-width:960px) {
          padding: 20px 10px;
        }
      }
      .title {
        color: #333333;
      }
    }
    &:nth-child(2) {
      background: url($image+"municipal-village-bg-2.jpg") center center;
      background-size: cover;
      .title {
        color: #ffffff;
      }
      .inside-block {
        padding: 80px 10px 80px 80px;
        @media (max-width:1200px) {
          padding: 80px 10px 80px 60px;
        }
        @media (max-width:960px) {
          padding: 20px 10px;
        }
      }
    }
  }
}
