.employment {
    font-size: 20px;
    .top-article {
        border: 6px solid #fff;
    }
    .ff-block {
        padding-top: 100px;
        color: #4c4c4c;
        h1, h2, h3, h4, h5, h6, p, ul, ol {
            color: inherit;
        }
        h2 {
            font-size: 27px;
        }
    }
    &--item {
            margin-bottom: 40px;
            text-align: left;
        &-info {
            background-color: #ebf0e0;
            border: 6px solid #fff;
            padding: 24px;
            margin-bottom: 40px;
        }
        &-btn {
            text-align: right;
            a.more {
                display: inline-block;
            }
        }
    }
    @media (max-width: 767px){
        font-size: 16px;
        .ff-block {
            padding-top: 50px;
            h2 {
                font-size: 22px;
            }
        }
    }
}