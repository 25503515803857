.sp1 {
  .item {
    margin: 10px 0;
    padding: 35px 0;

    background: #d8ebfe;
    .img-block {
      float: left;

      max-width: 50%;
      margin-right: 30px;

      border: 12px solid #7c8c5f;
      @media (max-width:780px) {
        display: table;
        float: none;

        max-width: 100%;
        margin: 0 auto 15px;
      }
      img {
        max-width: 100%;
      }
    }
    .title {
      margin-bottom: 15px;

      text-transform: uppercase;

      color: #376ea6;

      font-size: 30px;

      font-weight: 700;
      @media (max-width:950px) {
        font-size: 20px;
      }
    }
    ul {
      padding-left: 40px;

      list-style-image: url($image+"list-style-1.jpg");

      color: #4c6278;
    }
    table {
      margin-bottom: 10px;
      td {
        padding: 5px;
        &:not(:last-child) {
          padding-right: 30px;
          @media (max-width:780px) {
            padding-right: 10px;
          }
        }
      }
    }

    .more {
      color: #fff;
      background: #6095cb;
    }
  }
}
