.contact-page {
  padding: 40px 0;

  background-color: rgb(197,225,252);

  @include filter-gradient(#c5e1fc, #ffffff, vertical);
  @include background-image(linear-gradient(top,  rgba(197,225,252,1) 0%,rgba(255,255,255,1) 100%));

  .contact-info {
    text-align: center;
    img {
      max-width: 100%;
      margin-bottom: 10px;
    }
    .phone {
      display: table;

      margin: 0 auto;

      text-decoration: none;

      color: #3d3d3d;

      font-size: 27px;
      font-weight: 700;
      line-height: 1.1;
    }
    .address {
      display: block;

      margin: 10px 0;

      text-decoration: none;

      color: #4b7aa9;

      font-size: 20px;
      font-weight: 700;
    }
    p {
      color: #3d3d3d;

      font-family: $roboto;
      font-size: 16px;
      font-weight: 500;
      b {
        color: #4b7aa9;

        font-weight: 500;
      }
    }
  }

  .col-md-8 {
    border-left: 1px solid #6b5a4b;
  }

  .contact-form {
    .title {
      margin-bottom: 20px;

      text-transform: uppercase;

      color: #3d3d3d;

      font-family: $opensans;
      font-size: 25px;
      font-weight: 600;
    }
    [type=text],
    [type=email],
    [type=tel] {
      width: 100%;
      height: 40px;
      margin-bottom: 20px;
      padding: 0 15px;

      border: 1px solid #ebf0e0;
      background: #ebf0e0;

      font-family: $opensans;
      font-size: 17px;
      font-weight: 600;
      font-style: italic;
      @media (max-width:767px) {
        margin-bottom: 10px;
      }
      &.error {
        border-color: #f00;
      }
    }
    textarea {
      width: 100%;
      height: 130px;
      margin-bottom: 20px;
      padding: 10px 15px;

      resize: none;

      border: 1px solid #ebf0e0;
      background: #ebf0e0;

      font-family: $opensans;
      font-size: 17px;
      font-weight: 600;
      font-style: italic;
      @media (max-width:767px) {
        margin-bottom: 10px;
      }
      &.error {
        border-color: #f00;
      }
    }
    p {
      text-transform: uppercase;

      font-family: $opensans;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.2;
      @media (max-width:767px) {
        text-align: center;
      }
      a {
        text-decoration: none;

        color: #4b7aa9;
      }
    }
    [type=submit] {
      display: table;

      min-width: 140px;
      height: 40px;
      margin: 0 0 0 auto;
      padding: 0 15px;

      text-align: center;
      text-decoration: none;

      color: #fff;
      border: none;
      background: #6095cb;

      @include transition($transition-default);
      @media (max-width:767px) {
        margin: 0 auto;
      }
      .fa-refresh {
        display: none;
      }
      &:hover {
        opacity: .7;
      }
      &.send {
        .fa-refresh {
          display: block;
        }
        .value {
          display: none;
        }
      }
    }
  }
}
