.events-page {
  margin-bottom: 50px;
  .item {
    margin-bottom: 20px;
    padding: 35px 0;

    background: #d8ebfd;
  }
  .date {
    position: absolute;
    top: -40px;
    left: 15px;

    padding: 10px 15px;

    text-align: center;
    text-transform: uppercase;

    color: #e4f4fc;
    background: $green;

    font-size: 19px;
    font-weight: 700;
    line-height: 1;
    span {
      display: block;

      font-size: 50px;
    }
  }
  .img-block {
    position: relative;

    display: block;
    float: left;

    max-width: 50%;
    margin: 30px 15px 0 0;

    text-align: center;

    border: 14px solid #6095cb;
    @media (max-width:700px) {
      display: table;
      float: none;

      max-width: 100%;
      margin: 0 auto;
    }
    img {
      max-width: 100%;
    }
  }
  .content-block {
    padding-top: 30px;
    @media (max-width:700px) {
      padding-top: 15px;
    }
    .time {
      margin-bottom: 10px;

      text-transform: uppercase;

      color: #333333;

      font-size: 11px;
      font-weight: 700;
    }
    .name {
      display: block;

      margin-bottom: 15px;

      text-decoration: none;
      text-transform: uppercase;

      color: #333333;

      font-size: 18px;
      font-weight: 700;
      &:after {
        display: block;

        width: 40px;
        height: 2px;
        margin: 10px 0 0;

        content: "";

        background: #39a2ca;
      }
    }
    p {
      color: #494949;

      font-size: 15px;
    }
    .more {
      color: #fff;
      background: #3c6186;

    }
    .pdf{
      &:after {
        content: '';
        width: 80px;
        height: 67px;
        background-image: url($image+"ff-link-2.png");
      }
    }

    .blog-share-block {
      margin-top: 30px;
      b {
        text-transform: uppercase;

        color: #3d3d3d;

        font-size: 13px;
      }
      .common-share-block {
        margin-top: 5px;
        a {
          width: 45px;
          height: 45px;
          margin: 0 10px 0 0;

          text-align: center;
          text-decoration: none;

          color: #e4f4fc;
          background: #455a21;

          line-height: 45px;

          @include inline-block;
          @include transition($transition-default);
          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }
}
.events-navigation {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
  border-top: 1px solid #4b7aa9;
  font-size: 2rem;
  &:after {
    content: "";
    display: block;
    clear:both;
  }
  .current-month {
    display: inline-block;
    color: #4b7aa9;
    font-size: 2.5rem;
    transform: translateY(-15%);
    i {
      padding-right: 5px;
    }
  }
  .events-nav-link {
    color: #3c5772;
    transition: color .3s ease-in-out;

    &:hover {
      text-decoration: none;
      color: #4b7aa9;
    }
  }
  .events-prev-month {
    float: left;
    i {
      padding-right: 5px;
      order: 0;
    }
  }
  .events-next-month {
    float: right;
    order: 1;
    i {
      padding-left: 5px;
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    .events-prev-month,
    .events-next-month {
      float: none;
      flex: 1 0 50%;
      font-size: 1.75rem;
    }
    .current-month {
      order: 3;
      flex: 0 0 100%;
      margin-top: 1.5rem;
    }
  }
}
