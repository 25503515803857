@import "../../../vendor/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../vendor/font-awesome/scss/font-awesome";

@import 'compass';
@import 'lib/_reset';
@import 'lib/_var';

@include stickyFooter(500px, '#wrapper', '#footer');

$image: '../../images/';
$green: #455a21;

body {
  font-family: $montserrat;
  font-size: 17px;
  @media (max-width:950px) {
    font-size: 15px;
  }
}

#wrapper {
  overflow: hidden;
}

a.more {
  display: table;

  min-width: 140px;
  padding: 0 15px;

  text-align: center;
  text-decoration: none;

  line-height: 40px;

  @include transition($transition-default);
  &:hover {
    opacity: .7;
  }
}

h2 {
  margin: 0 0 10px;
  text-transform: uppercase;


  color: #333333;

  font-size: 23px;
  font-weight: 700;
  @media (max-width:950px) {
    font-size: 20px;
  }
}

@import "modules/_header";

@import "modules/_top-slider";
@import "modules/_subslider-block";
@import "modules/_top-article";
@import "modules/_common-icons";
@import "modules/_upcoming-events";
@import "modules/_efb";
@import "modules/_village-news";
@import "modules/_four";
@import "modules/_subscribe";
@import "modules/_municipal-village";
@import "modules/_sh-village-history";
@import "modules/_community-sites";
@import "modules/_acmb";
@import "modules/_newsland-events-links";
@import "modules/_events-filter";
@import "modules/_events-page";
@import "modules/_boards-committees-list";
@import "modules/_blog-page";
@import "modules/_map";
@import "modules/_contact-page";
@import "modules/_back-block";
@import "modules/_residents-list";
@import "modules/_residentsMap";
@import "modules/_what-say";
@import "modules/_ff-block";
@import "modules/_pc-block";
@import "modules/_sp1";
@import "modules/_contact-police-page";
@import "modules/_report-tip";
@import "modules/_police-social";
@import "modules/_police-links";
@import "modules/_annual-reports";
@import "modules/_employment";
@import "modules/_application-contact-form";

@import "modules/_footer";
