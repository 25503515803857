.police--link {
    &s {
        display: flex;
        flex-wrap: wrap;
    }
    &-item {
        background-color: #376EA6;
        color: #fff;
        border: solid 3px #fff;
        font-family: $raleway;
        font-size: 30px;
        flex: 1 1 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        transition: all .4s ease-in-out;
        img {
            margin-right: 20px;
        }
        &:hover {
            text-decoration: none;
            background-color: #607040;
            color: #fff;
        }
    }
    @media screen and (max-width: 1400px){
        &-item {
            font-size: 20px;
            line-height: 1;
            img {
                max-height: 40px;
                margin-right: 10px;
            }
        }
    }
    @media screen and (max-width: 991px){
        &-item {
            font-size: 16px;
            flex: 1 1 50%;
        }
    }
    @media screen and (max-width: 470px){
        &-item {
            flex: 1 1 100%;
        }
    }
}