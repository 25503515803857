.efb {
  overflow: hidden;

  margin: 0 -1px;
  .item {
    float: left;

    width: (100%/3);
    padding: 0 1px;
    @media (max-width:750px) {
      float: none;

      width: 100%;
    }
    .inside {
      position: relative;

      display: block;

      text-decoration: none;

      border-bottom: 7px solid #7c8c5f;
      img {
        width: 100%;
      }
      .text {
        position: absolute;
        right: 0;
        bottom: 40px;
        left: 0;

        text-align: center;
        text-transform: uppercase;

        color: #ffffff;

        font-size: 40px;
        font-weight: 700;

        @include transition($transition-default);
        @media (max-width:1200px) {
          bottom: 30px;

          font-size: 30px;
        }
        @media (max-width:900px) {
          bottom: 20px;

          font-size: 20px;
        }
      }
      &:hover {
        .text {
          bottom: 50px;
          @media (max-width:1200px) {
            bottom: 40px;
          }
          @media (max-width:900px) {
            bottom: 30px;
          }
        }
      }
    }
  }
}
