.back-block {
  text-align: left;

  font-weight: 700;
  a {
    text-decoration: none;

    text-transform: uppercase;

    color: #7f8e62;

    font-size: 17px;
    i {
      position: relative;
      left: 0;

      @include transition($transition-default);
    }
    &:hover {
      color: #6095cb;
      i {
        left: -5px;
      }
    }
  }
}
