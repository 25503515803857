.subslider-block {
  padding: 18px 10px;

  text-align: center;

  background: #607040;
  img {
    @include inline-block;
  }
  p {
    margin: 0 35px;

    color: #fff;

    font-size: 27px;
    font-weight: 700;

    @include inline-block;
    @media (max-width:750px) {
      display: block;

      font-size: 20px;
      margin: 10px 0;
    }
  }
  .more {
    color: #b2bd9f;
    background: #455a21;

    @include inline-block;
  }
}
