.events-filter {
  text-align: center;
  a {
    margin: 0 50px;

    text-decoration: none;
    text-transform: uppercase;

    color: #3d3d3d;

    font-size: 30px;
    font-weight: 700;
    line-height: 60px;

    @include inline-block;
    @include transition($transition-default);
    @media (max-width:700px) {
      margin: 0 10px;
    }
    @media (max-width:500px) {
      display: block;
      line-height: 1.5;
      font-size: 20px;
    }
    &:hover {
      color: #4b7aa9;
    }
    &.active {
      text-decoration: underline;

      color: #4b7aa9;
    }
  }
}
