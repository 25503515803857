.pc-block {
  padding: 30px 0;

  text-align: center;

  background-color: rgb(219,237,253);

  @include filter-gradient(#dbedfd, #ffffff, vertical);
  @include background-image(linear-gradient(top,  rgba(219,237,253,1) 0%,rgba(255,255,255,1) 100%));

  .block-center {
    max-width: 1050px;
  }

  .personal-list {
    overflow: hidden;
    margin: 0 -15px 50px;
    h2 {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (max-width:650px) {
      margin: 0 -15px 10px;
    }
    .item {
      float: left;

      width: (100%/3);
      padding: 0 15px;
      @media (max-width:900px) {
        width: (100%/2);
      }
      @media (max-width:600px) {
        width: 100%;
      }
    }
    .inside {
      position: relative;

      margin-bottom: 25px;
      padding: 20px 20px 40px;

      text-align: left;

      border: 12px solid #607040;
      @media (max-width:650px) {
        margin-bottom: 10px;
      }
      .name {
        text-transform: uppercase;

        color: #333333;

        font-size: 22px;
        font-weight: 700;
        line-height: 1;
        &:after {
          display: block;

          width: 40px;
          height: 1px;
          margin: 5px 0;

          content: "";

          background: #3c5772;
        }
      }
      .job {
        color: #607040;

        font-family: $roboto;
        font-size: 19px;
        font-weight: 700;
      }
      .mail {
        position: absolute;
        right: 0;
        bottom: 0;

        width: 50px;
        height: 40px;

        text-align: center;
        text-decoration: none;

        color: #ebf0e0;
        background: #74894a;

        font-size: 30px;
        line-height: 40px;

        @include transition($transition-default);
        &:hover {
          opacity: .8;
        }
      }
    }
  }

  .links {
    text-align: center;
    .more {
      position: relative;

      margin: 0 10px 20px;
      padding: 0 80px 0 20px;

      text-transform: uppercase;

      color: #fff;
      background: #6095cb;

      font-size: 15px;
      line-height: 65px;

      @include inline-block;
      @media (max-width:650px) {
        display: block;

        margin: 0 0 10px;
        padding: 5px 15px;

        font-size: 13px;
        line-height: 30px;
      }
      .fa {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        width: 60px;

        border-left: 1px solid #fff;

        line-height: 65px;
        @media (max-width:650px) {
          display: none;
        }
      }
    }
  }
}
