.what-say {
  padding: 50px 0;

  text-align: center;
  @media (max-width:600px) {
    padding: 20px 0;
  }
  #whatSay {
    .inside {
      max-width: 900px;
      margin: 0 auto;
      padding: 15px 100px 0;

      background: url($image+"what-say-quote-left.png") top left 35px no-repeat,
                  url($image+"what-say-quote-right.png") bottom right 35px no-repeat;
      @media (max-width:600px) {
        padding: 15px 35px 0;

        background: none;
      }
      p {
        color: #4c4c4c;

        font-family: $roboto;
        font-size: 18px;
        font-style: italic;
        @media (max-width:600px) {
          font-size: 15px;
        }
      }
      .author {
        color: #6095cb;

        font-size: 18px;
        @media (max-width:600px) {
          font-size: 15px;
        }
      }
    }
    .carousel_control {
      position: absolute;
      top: 50%;

      padding: 10px;

      text-decoration: none;

      @include transform(translateY(-50%));
      @media (max-width:600px) {
        padding: 5px;
      }
      span {
        display: block;

        color: #85946a;

        font-size: 14px;
        font-weight: 700;
      }
      &.left {
        left: 0;

        border-right: 1px solid #dddddd;
      }
      &.right {
        right: 0;

        border-left: 1px solid #dddddd;
      }
    }
  }
}
