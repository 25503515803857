.top-article {
  padding: 45px 0;

  text-align: center;

  background: #ebf0e0;

  p {
    color: #4c4c4c;
    b {
      color: #4b7aa9;
    }
  }
  .more {
    margin: 30px auto 0;

    text-transform: uppercase;

    color: #fff;
    background: #6095cb;
  }
  &.sp2 {
    text-align: left;
    p {
      color: #5a624c;
      b {
        color: #5a624c;
      }
    }
    h2 {
      text-align: center;
    }
    h3 {
      text-align: center;
      text-decoration: underline;

      color: #4c6278;
    }
    h4 {
      color: #3c5772;

      font-weight: 700;

    }
    ul {
      padding-left: 40px;

      list-style-image: url($image+"list-style-1.jpg");

      color: #4c6278;
    }
    ol {
      padding-left: 40px;

      color: #4c6278;
    }
    table {
      margin-bottom: 10px;

      color: #5a624c;
      td {
        padding: 5px;
        &:not(:last-child) {
          padding-right: 30px;
          @media (max-width:780px) {
            padding-right: 10px;
          }
        }
      }
    }
  }
}
