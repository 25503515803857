.community-sites {
  padding: 45px 0;

  text-align: center;

  background-color: rgb(219,237,253);

  @include filter-gradient(#dbedfd, #ffffff, vertical);
  @include background-image(linear-gradient(top,  rgba(219,237,253,1) 0%,rgba(255,255,255,1) 100%));

  .block-center {
    max-width: 1920px;
  }

  .community-sites-list {
    text-align: left;

    font-size: 0;
    .item {
      display: inline-block;

      width: (100%/3);
      margin: 25px 0;
      padding: 0 25px;

      vertical-align: top;
      @media (max-width:1650px) {
        padding: 0 10px;
      }
      @media (max-width:1400px) {
        width: (100%/2);
      }
      @media (max-width:950px) {
        width: 100%;
        margin: 10px 0;
        padding: 0;
      }
      .inside {
        position: relative;

        display: block;

        padding: 16px 0;

        text-decoration: none;

        font-size: 17px;
        @media (max-width:1650px) {
          font-size: 15px;
        }
        @media (max-width:950px) {
          padding: 0;
        }
        .img-block {
          position: absolute;
          top: 0;
          left: 0;

          overflow: hidden;

          border: 14px solid $green;

          @include border-radius(50%);
          @media (max-width:500px) {
            position: static;

            @include border-radius(0);
          }
          img {
            max-width: 100%;
            @media (max-width:500px) {
              width: 100%;
            }
          }
        }
        .content-block {
          min-height: 210px;
          margin-left: 100px;
          padding: 15px 15px 15px 150px;

          background: #deeefd;
          @media (max-width:500px) {
            min-height: 0;
            margin-left: 0;
            padding: 10px;
          }
          .title {
            margin-bottom: 5px;

            text-transform: uppercase;

            color: #376ea6;

            font-size: 25px;

            font-weight: 700;
            line-height: 1;
            @media (max-width:1650px) {
              font-size: 20px;
            }
          }
          p {
            color: #4c4c4c;

            font-weight: 700;
            line-height: 1.3;
          }
          .read-more {
            color: #7c8c5f;
            i {
              position: relative;
              left: 0;

              @include transition($transition-default);
            }
          }
        }
        &:hover {
          .read-more i {
            left: 5px;
          }
        }
      }
    }
  }

  .more {
    margin: 30px auto 0;

    color: #fff;
    background: #6095cb;
  }
}
