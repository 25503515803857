#map {
  width: 100%;
  height: 450px;
  @media (max-width:767px) {
    height: 300px;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  p {
    font-size: 16px;
  }
  .more {
    margin: 30px auto 0;
    text-transform: uppercase;
    color: #fff;
    background: #6095cb;
    margin-top: 10px;
    font-size: 16px;
  }
}
