.annual-report {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    font-family: $montserrat;
    font-size: 30px;
    font-weight: 700;
    color: #3C5672;
    transition: all .4s ease-in-out;
    margin-bottom: 12px;
    &:last-of-type {
        margin-bottom: 0;
    }
    &:after {
        content: "";
        background-image: url(../../images/pdf-download.svg);
        background-repeat: no-repeat;
        background-size: contain;
        height: 46px;
        width: 50px;
        display: block;
        margin-left: 1.5rem;
    }
    &:hover {
        color: #3C5672 !important;
        background-color: #f7f7f7 !important;
        text-decoration: none;
    }
    &:nth-child(even){
        background-color: #D6DDC7;
    }
    &:nth-child(odd){
        background-color: #C1CAC9;
    }
    &s--container {
        background-color: #EBF0E0;
        padding-bottom: 12px;
    }
    @media screen and (max-width: 1400px){
        font-size: 20px;
        &:after {
            height: 30px;
        }
    }
    @media screen and (max-width: 991px){
        font-size: 16px;
        &:after {
            height: 26px;
        }
    }
}