.acmb {
  padding: 50px 0;

  text-align: center;

  background-color: rgb(219,237,253);

  @include filter-gradient(#dbedfd, #ffffff, vertical);
  @include background-image(linear-gradient(top,  rgba(219,237,253,1) 0%,rgba(255,255,255,1) 100%));

  .block-center {
    max-width: 1280px;
  }

  .item {
    float: left;

    width: 50%;
    padding: 0 30px 45px;
    @media (max-width:1000px) {
      padding: 0 10px 20px;
    }
    @media (max-width:670px) {
      width: 100%;
      padding: 0 0 20px;
    }
    .inside {
      position: relative;

      display: block;

      text-decoration: none;

      border: 15px solid #6095cb;
      img {
        width: 100%;
      }
      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;

        content: "";

        opacity: 0;
        background: rgba(96, 112, 64, .6);

        @include transition($transition-default);
      }
      .content {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        padding: 50px 0 15px;

        text-align: center;

        background: rgba(24, 28, 35, .75);

        @include transition($transition-default);
        @media (max-width:760px) {
          padding: 30px 0 10px;
        }
        span {
          text-transform: uppercase;

          color: #fff;

          font-size: 37px;

          font-weight: 700;
          @media (max-width:1150px) {
            font-size: 30px;
          }
          @media (max-width:1000px) {
            font-size: 25px;
          }
          @media (max-width:760px) {
            font-size: 20px;
          }
        }
        i {
          display: block;

          opacity: 0;
          color: #607040;

          font-size: 100px;
          line-height: 40px;

          @include transition($transition-default);
          @media (max-width:760px) {
            font-size: 50px;
            line-height: 20px;
          }
        }
      }
      &:hover {
        &:before {
          opacity: 1;
        }
        .content {
          bottom: 50%;

          @include transform(translateY(50%));
          i {
            opacity: 1;
          }
        }
      }
    }
  }
}
