.top-slider {
  position: relative;

  min-height: 280px;
  .carousel-inner {
    .item {
      min-height: 280px;
      .img-block {
        width: 100%;
        height: 100%;

        background-position: center;
        background-size: cover;
      }
      .slider-content {
        position: absolute;
        top: calc(50% + 93px);
        left: 50%;

        width: 100%;
        max-width: 1180px;

        @include transform(translateY(-50%) translateX(-50%));
        @media (max-width:1300px) {
          padding: 0 30px;
        }
        @media (max-width:950px) {
          top: 50%;

          padding: 0 10px;
        }
        .title-1 {
          display: table;

          margin: 4px 0;
          padding: 0 15px;

          text-align: center;

          color: #fff;
          background: rgba(96, 112, 64, .8);

          font-family: $opensans;
          font-size: 30px;
          font-weight: 600;
          line-height: 45px;
          @media (max-width:1100px) {
            margin: 4px auto;

            font-size: 20px;
            line-height: 30px;
          }
          @media (max-width:400px) {
            font-size: 15px;
            line-height: 1.4;
          }
        }
        .title-2 {
          display: table;

          margin: 4px 0;
          padding: 0 15px;

          text-align: center;

          color: #fff;
          background: rgba(37, 41, 47, .8);

          font-family: $raleway;
          font-size: 55px;
          font-weight: 800;
          line-height: 75px;
          @media (max-width:1100px) {
            margin: 4px auto;

            font-size: 30px;
            line-height: 40px;
          }
          @media (max-width:400px) {
            font-size: 23px;
            line-height: 1.4;
          }
        }
        .title-3 {
          display: table;

          margin: 4px 0;
          padding: 0 15px;

          text-align: center;

          color: #fff;
          background: rgba(37, 41, 47, .8);

          font-family: $raleway;
          font-size: 26px;
          line-height: 45px;
          @media (max-width:1100px) {
            margin: 4px auto;

            font-size: 18px;
            line-height: 30px;
          }
          @media (max-width:400px) {
            font-size: 15px;
            line-height: 1.4;
          }
        }
        .see-more {
          display: table;

          min-width: 140px;
          padding: 0 15px;

          text-align: center;
          text-decoration: none;

          color: #fff;
          background: #6095cb;

          line-height: 40px;
          @media (max-width:1100px) {
            margin: 4px auto;
          }
        }
      }
    }
  }
  .carousel_control {
    position: absolute;
    top: calc(50% + 93px);

    width: 60px;
    height: 60px;
    margin-top: -30px;

    background: url($image+"top-slider-control.png");
    @media (max-width:1300px) {
      width: 30px;
      height: 30px;
      margin-top: -15px;

      background-size: auto 100%;
    }
    @media (max-width:950px) {
      display: none;
    }
    &.left {
      left: 60px;
      @media (max-width:1450px) {
        left: 10px;
      }
    }
    &.right {
      right: 60px;

      background-position: right;
      @media (max-width:1450px) {
        right: 10px;
      }
    }
  }
}
