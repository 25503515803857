.boards-committees-list {
  overflow: hidden;
  .item {
    position: relative;

    display: block;
    float: left;

    width: (100%/4);

    text-decoration: none;
    @media (max-width:1100px) {
      width: (100%/2);
    }
    @media (max-width:550px) {
      width: 100%;
    }
    img {
      width: 100%;
    }
    .text {
      position: absolute;
      right: 0;
      bottom: 30px;
      left: 0;

      height: 70px;
      padding: 5px 0;

      text-align: center;
      text-transform: uppercase;

      color: #fff;
      background: rgba(0, 0, 0, .6);

      font-size: 30px;
      font-weight: 700;
      line-height: 1;

      @include transition($transition-default);
      @media (max-width:1400px) {
        font-size: 20px;
      }
      .td {
        position: relative;
        top: 50%;

        @include transform(translateY(-50%));
      }
    }
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      content: "";

      opacity: 0;
      background: rgba(83, 96, 61, .65);

      @include transition($transition-default);
    }
    &:hover {
      .text {
        bottom: 50px;
      }
      &:before {
        opacity: 1;
      }
    }
  }
}
