#residentsMap {
  width: 100%;

  max-width: 700px;
  height: 350px;
  margin: 0 auto;

  text-align: left;
  b,
  p {
    font-size: 16px;
  }
  .more {
    margin-top: 10px;

    font-size: 16px;
  }
}
