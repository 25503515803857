.ff-block {
  padding: 30px 0;

  text-align: center;

  background-color: rgb(219,237,253);

  @include filter-gradient(#dbedfd, #ffffff, vertical);
  @include background-image(linear-gradient(top,  rgba(219,237,253,1) 0%,rgba(255,255,255,1) 100%));

  .block-center {
    max-width: 1050px;
  }

  h2 {
    margin-bottom: 20px;

    font-size: 33px;
    @media (max-width:950px) {
      font-size: 20px;
    }
  }

  .link-list {
    margin-bottom: 40px;

    text-align: left;
    a {
      position: relative;

      display: block;

      margin-bottom: 10px;
      padding: 15px 100px 15px 30px;

      text-decoration: none;
      text-transform: uppercase;

      color: #333333;
      border: 4px solid #ffffff;
      background: #f0f6e4;

      font-size: 26px;
      font-weight: 700;
      @media (max-width:600px) {
        padding: 5px 50px 5px 15px;

        font-size: 16px;
      }
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        display: block;

        width: 80px;

        content: "";

        border-left: 4px solid #ffffff;
        background: #7c8c5f url($image+"ff-link-1.png") center center no-repeat;
        @media (max-width:600px) {
          width: 45px;

          background-size: 22px auto;
        }
      }
      &.pdf {
        &:after {
          background-image: url($image+"ff-link-2.png");
        }
      }
    }
  }
  .more {
    position: relative;

    margin: 0 auto;
    padding: 0 90px 0 30px;

    color: #fff;
    background: #6095cb;

    line-height: 65px;
    .fa {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      width: 60px;

      border-left: 1px solid #fff;

      line-height: 65px;
    }
  }
}
