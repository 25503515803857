#header {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;

  border-top: 10px solid $green;
  background: rgba(82, 115, 148, 0.75);
  @media (max-width:950px) {
    position: static;
  }
  .header-top {
    .block-center {
      max-width: 1080px;
    }
  }
  .slogan {
    float: left;

    padding: 42px 0 42px 20px;

    color: #fafafa;

    font-family: $master;
    font-size: 23px;
    @media (max-width:950px) {
      float: none;

      padding: 10px 20px;

      text-align: center;

      font-size: 18px;
    }
  }
  .logo {
    position: absolute;
    z-index: 5;
    top: -10px;
    left: 50%;

    margin-left: -155px;
    @media (max-width:950px) {
      position: static;
      top: 0;
      left: 0;

      display: block;

      margin: 0;

      text-align: center;
    }
    img {
      max-width: 100%;
    }
  }
  .social-search {
    float: right;
    @media (max-width:950px) {
      float: none;

      margin-top: 10px;
    }
    .social {
      margin-bottom: 30px;

      text-align: center;
      @media (max-width:950px) {
        margin-bottom: 10px;
      }
      a {
        width: 35px;
        height: 35px;
        margin-left: 5px;

        text-decoration: none;

        color: #7690a6;
        background: $green;

        font-size: 23px;
        line-height: 35px;

        @include inline-block;
        @include transition($transition-default);
        &:hover {
          opacity: .7;
        }
      }
    }
    .search {
      overflow: hidden;

      width: 250px;
      margin: 0 auto;

      border: 1px solid $green;
      background: #7092b3;

      @include border-radius(15px);
      @media (max-width:950px) {
        margin: 0 auto 10px;
      }
      .fa {
        float: left;

        width: 30px;

        text-align: center;

        color: #fff;

        font-size: 13px;
        line-height: 28px;
      }
      input {
        width: calc(100% - 30px);
        height: 28px;

        color: #fff;
        border: none;
        background: none;

        font-size: 13px;

        @include input-placeholder {
          color: #fff;
        }
      }
    }
  }
  .menu-block {
    position: relative;

    border-top: 3px solid #fff;
    background: #3c5772;
    .menu-button {
      display: none;

      width: 100%;
      padding: 10px 0;

      border: none;
      background: none;
      @media (max-width:950px) {
        display: block;
      }
      span {
        display: block;

        width: 35px;
        height: 4px;
        margin: 5px auto;

        background: #fff;
      }
    }
    menu {
      margin-top: 17px;

      text-align: center;
      @media (max-width:950px) {
        position: absolute;
        z-index: 15;
        top: 52px;
        right: 0;
        left: 0;

        display: none;

        margin-top: 0;

        border-top: 1px solid #fff;
        background: #3c5772;
        &.open {
          display: block;
        }
      }
      li {
        margin: 0 17px;

        @include inline-block;
        @media (max-width:1200px) {
          margin: 0 10px;
        }
        @media (max-width:1100px) {
          margin: 0 5px;
        }
        @media (max-width:950px) {
          display: block;
        }
        a {
          display: block;

          text-decoration: none;

          color: #fff;

          font-size: 18px;
          font-weight: 700;
          line-height: 40px;

          @include transition($transition-default);
          @media (max-width:1100px) {
            font-size: 16px;
          }
          &:hover {
            color: #cadaae;
          }
          &.active {
            position: relative;

            color: #cadaae;
            &:after {
              position: absolute;
              bottom: -3px;
              left: 0;

              width: 100%;
              height: 7px;

              content: "";

              background: #607040;
            }
          }
        }
      }
    }
  }
}
