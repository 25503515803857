.blog-page {
  padding: 20px 0;
  @media (max-width:768px) {
    padding: 10px 0;
  }
  .blog-list {
    .item {
      overflow: hidden;

      margin-bottom: 40px;
      padding: 30px;

      background: #d8ebfd;
      @media (max-width:768px) {
        margin-bottom: 20px;
        padding: 10px;
      }
      .img-block {
        display: block;
        float: left;

        max-width: 50%;
        margin: 0 15px 10px 0;

        text-align: center;

        border: 15px solid #6095cb;
        @media (max-width:650px) {
          display: table;
          float: none;

          max-width: 100%;
          margin: 0 auto 15px;

          border-width: 5px;
        }
        img {
          max-width: 100%;
        }
      }
      .date {
        margin-bottom: 10px;

        text-transform: uppercase;

        color: #333333;

        font-size: 11px;
        font-weight: 700;
      }
      .name {
        display: block;

        margin-bottom: 15px;

        text-decoration: none;
        text-transform: uppercase;

        color: #333333;

        font-size: 18px;
        font-weight: 700;
        &:after {
          display: block;

          width: 40px;
          height: 2px;
          margin: 10px 0 0;

          content: "";

          background: #39a2ca;
        }
      }
      p {
        color: #494949;

        font-size: 15px;
      }
      .more {
        color: #fff;
        background: #3c6186;
      }
      .blog-share-block {
        margin-top: 20px;
        b {
          text-transform: uppercase;

          color: #3d3d3d;

          font-size: 13px;
        }
        .common-share-block {
          margin-top: 5px;
          a {
            width: 45px;
            height: 45px;
            margin: 0 10px 0 0;

            text-align: center;
            text-decoration: none;

            color: #e4f4fc;
            background: #455a21;

            line-height: 45px;

            @include inline-block;
            @include transition($transition-default);
            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }
  }
  .pagination {
    margin-bottom: 40px;
    @media (max-width:768px) {
      margin-bottom: 20px;
    }
    a {
      display: inline-block;

      width: 50px;
      height: 50px;

      text-align: center;
      vertical-align: top;
      text-decoration: none;

      color: #6095cb;
      border: 3px solid #455a21;

      font-size: 14px;
      font-weight: 700;
      line-height: 44px;

      @include transition($transition-default);
      @media (max-width:768px) {
        width: 30px;
        height: 30px;

        border: 2px solid #455a21;

        line-height: 26px;
      }
      &:hover,
      &.active {
        color: #fff;
        border-color: #6095cb;
        background: #6095cb;
      }
    }
  }
  .archives-block {
    .title-asaid {
      margin-bottom: 20px;

      color: #3c5772;

      font-size: 25px;
      font-weight: 700;
      @media (max-width:991px) {
        margin-top: 15px;

        font-size: 18px;
      }
    }
    .archives-list {
      margin-bottom: 25px;
      .item {
        display: block;

        padding: 10px 0;

        text-decoration: none;

        color: #3d3d3d;
        border-bottom: 1px solid #ebebeb;

        font-size: 18px;
        font-weight: 700;

        @include transition($transition-default);
        &:before {
          position: relative;
          left: 0;

          margin-right: 15px;

          content: '\f105';

          color: #232323;

          font-family: "FontAwesome";

          @include transition($transition-default);
        }
        &:hover {
          color: #4b7aa9;
          &:before {
            left: 5px;
          }
        }
      }
    }
  }
  .twitter-feed {
    margin-top: 50px;
    .title {
      margin-bottom: 15px;

      text-transform: uppercase;

      color: #3c6186;

      font-size: 14px;
      font-weight: 700;

      &:after {
        display: block;

        width: 45px;
        height: 1px;

        content: '';

        background: #ebebeb;
      }
    }
    .twitter-list {
      .item {
        margin-bottom: 20px;
        p {
          color: #888888;

          font-size: 12px;
          a {
            text-decoration: none;

            color: #3c6186;
            &:before {
              margin-right: 5px;

              content: '\f099';

              font-family: $awesome;
            }
          }
        }
        .time {
          color: #888888;

          font-size: 12px;
        }
      }
    }
  }
}
