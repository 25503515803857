#footer {
  overflow: hidden;

  background: url($image+"footer-bg.jpg");
  background-size: cover;
  .footer-top {
    padding: 70px 0;

    font-size: 0;
    @media (max-width:500px) {
      padding: 20px 0;
    }
    .footer-block {
      display: inline-block;

      padding: 0 20px;

      vertical-align: top;

      border-right: 1px solid #34748c;

      font-family: $roboto;
      font-size: 14px;
      font-weight: 500;
      @media (max-width:1000px) {
        margin-bottom: 15px;
      }
      @media (max-width:500px) {
        padding: 0;
      }
      &:nth-child(1) {
        width: (100%/3);
        @media (max-width:1000px) {
          width: 50%;
        }
        @media (max-width:500px) {
          width: 100%;

          border-right: none;
        }
      }
      &:nth-child(2) {
        width: (100%/4);
        @media (max-width:1000px) {
          width: 50%;

          border-right: none;
        }
        @media (max-width:500px) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: (100%/6);
        @media (max-width:1000px) {
          width: 50%;
        }
        @media (max-width:500px) {
          width: 100%;

          border-right: none;
        }
      }
      &:nth-child(4) {
        width: (100%/4);

        border-right: none;
        @media (max-width:1000px) {
          width: 50%;
        }
        @media (max-width:500px) {
          width: 100%;
        }
      }

      .footer-logo {
        max-width: 100%;
        margin: -15px 0 15px;
      }
      p {
        color: #ebf0e0;
      }
      .footer-contact {
        margin-top: 25px;

        color: #83bbd4;
        .item {
          margin-bottom: 5px;
        }
        i {
          width: 25px;
          margin-right: 10px;

          border-right: 1px solid #83bbd4;
        }
        a {
          text-decoration: none;

          color: #83bbd4;

        }
      }
      .title {
        margin-bottom: 25px;

        text-transform: uppercase;

        color: #ffffff;

        font-family: $montserrat;
        font-size: 15px;
        &:after {
          display: block;

          width: 30px;
          height: 3px;
          margin-top: 15px;

          content: "";

          background: #85946a;
        }
      }
      .twitter-feed {
        .item {
          margin-bottom: 25px;
          .time {
            color: #6692a6;
          }
        }
      }
      .useful-links {
        a {
          display: table;

          margin-bottom: 15px;

          text-decoration: none;
          text-transform: uppercase;

          color: #fff;

          font-size: 13px;

          @include transition($transition-default);
          &:hover {
            color: #6692a6;
          }
        }
      }
      .instafeed {
        overflow: hidden;

        margin: 0 -7px 70px;
        @media (max-width:500px) {
          margin: 0 -7px 10px;
        }
        a {
          float: left;

          width: (100%/3);
          padding: 0 7px 14px;
          img {
            width: 100%;
          }
        }
      }
      .search {
        overflow: hidden;

        width: 100%;

        border: 1px solid $green;
        background: #7092b3;

        @include border-radius(15px);
        .fa {
          float: left;

          width: 30px;

          text-align: center;

          color: #fff;

          font-size: 13px;
          line-height: 28px;
        }
        input {
          width: calc(100% - 30px);
          height: 28px;

          color: #fff;
          border: none;
          background: none;

          font-size: 13px;

          @include input-placeholder {
            color: #fff;
          }
        }
      }
    }
  }
  .footer-links {
    padding: 15px 10px;

    text-align: center;

    color: #e4eee1;
    background: rgba(95, 106, 75, .9);

    font-family: $roboto;
    font-size: 17px;
    font-weight: 500;
    a {
      margin: 0 15px;

      white-space: nowrap;
      text-decoration: none;
      text-transform: uppercase;

      color: #e4eee1;

      @include transition($transition-default);
      @media (max-width:400px) {
        display: block;
      }
      &:hover {
        color: #6692a6;
      }
    }
    span {
      @media (max-width:400px) {
        display: none;
      }
    }
  }
  .footer-bottom {
    overflow: hidden;

    padding: 20px 0;
    .copy {
      float: left;

      color: #ffffff;

      font-size: 14px;
      line-height: 32px;
      @media (max-width:400px) {
        float: none;

        text-align: center;
      }
    }
    #goToTop {
      float: right;

      color: #fff;
      border: none;
      background: none;
      @media (max-width:400px) {
        float: none;

        width: 100%;
      }
      i {
        color: #95b458;

        font-size: 30px;
      }
    }
    .social {
      overflow: hidden;

      text-align: center;
      @media (max-width:700px) {
        clear: both;

        padding-top: 15px;
      }
      a {
        width: 45px;
        height: 45px;
        margin: 0 3px;

        text-decoration: none;

        color: #1b242d;

        font-size: 30px;
        line-height: 45px;

        @include inline-block;
        @include transition($transition-default);
        @media (max-width:350px) {
          width: 40px;
          height: 40px;

          line-height: 40px;
        }
        &.fa-facebook {
          background: #3a5897;
        }
				&.fa-twitter {
          background: #49c9f2;
        }
				&.fa-instagram {
          background: #3b749f;
        }
				&.fa-pinterest-p {
          background: #cd2129;
        }
				&.fa-linkedin {
          background: #0775b7;
        }
				&.fa-youtube {
          background: #ce332c;
        }
        &:hover {
          opacity: .8;
        }
      }
    }
  }
}
