.application-contact-form {
    input, textarea {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 15px;
        border: 1px solid #f5faea;
        background-color: #fff;
        font-size: 17px;
        font-weight: 600;
        @media (max-width: 768px){
            font-size: 14px;
            padding: 0 6px;
        }
    }
    input {
        height: 40px;
        &[type="radio"]{
            height: 20px;
            width: auto;
            display: inline-block;
        }
    }
    ::placeholder {
        color: #8a8a8a;
    }
    textarea {
        padding-top: 15px;
        padding-bottom: 15px;
        @media (max-width: 768px){
            padding: 6px;
        }
    }
    input, textarea {
        &.error {
            border-color: #f00;
        }
    }
    .inline-block {
        p,
        input,
        label {
            display: inline-block;
        }
        input + label,
        p + label {
            margin-left: 2rem;
        }
        @media (max-width: 768px){
            p {
                display: block;
            }
        }
    }
    table {
        td {
            padding-right: 0 !important;
        }
        th {
            text-align: center !important;
        }
        @media (max-width: 768px){
            th {
                font-size: 9px;
            }
            td input {
                font-size: 10px;
            }
        }
    }
    .repeater {
        margin-top: 3rem;
    }
    div[class^='clonedpart']{
        margin-top: 3rem;
    }
    .cloneBtn {
        cursor: pointer;
        color: #3d3d3d;
        font-weight: 700;
    }
    button[type="submit"] {
      min-width: 140px;
      height: 40px;
      margin: 0 0 0 auto;
      padding: 0 15px;

      text-align: center;
      text-decoration: none;

      color: #fff;
      border: none;
      background: #6095cb;

      @include transition($transition-default);
      @media (max-width:767px) {
        margin: 0 auto;
      }
      &:hover {
        opacity: .7;
      }
    }

}