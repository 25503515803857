.icon-1 {
  position: relative;

  display: table;

  width: 85px;
  height: 85px;
  margin: 0 auto 35px;

  text-align: center;

  background: #7c8c5f;

  @include transform(rotate(45deg));
  .inside {
    display: table-cell;

    width: 85px;
    height: 85px;

    vertical-align: middle;

    @include transform(rotate(-45deg));
    span {
      display: block;

      text-transform: uppercase;

      color: #b2bd9f;

      font-size: 12px;
      font-weight: 700;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: -330px;

      display: block;

      width: 300px;
      height: 1px;

      content: "";

      background: #b2bd9f;
    }
    &:after {
      position: absolute;
      top: 50%;
      right: -330px;

      display: block;

      width: 300px;
      height: 1px;

      content: "";

      background: #b2bd9f;
    }
  }
}
