.village-news {
  padding: 45px 0;

  text-align: center;

  background-color: rgb(197,225,252);

  @include filter-gradient(#c5e1fc, #ffffff, vertical);
  @include background-image(linear-gradient(top,  rgba(197,225,252,1) 0%,rgba(255,255,255,1) 100%));

  .events-links {
    text-align: right;

    color: #333333;

    font-size: 20px;
    a {
      margin: 0 5px;

      text-decoration: none;

      color: #607040;

      @include transition($transition-default);
      &:hover {
        color: #6095cb;
      }
    }
  }
  .news-list {
    overflow: hidden;

    margin: 10px -15px 0;

    text-align: left;

    .item {
      float: left;

      width: 50%;
      padding: 0 15px 10px;
      @media (max-width:800px) {
        float: none;
      }
      &:nth-child(1) {
        @media (max-width:1000px) {
          width: 40%;
        }
        @media (max-width:800px) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        @media (max-width:1000px) {
          width: 60%;
        }
        @media (max-width:800px) {
          width: 100%;
        }
      }
    }
    .top-news {
      position: relative;

      display: block;
      overflow: hidden;

      text-decoration: none;

      border: 15px solid #6095cb;
      @media (max-width:800px) {
        border: 5px solid #6095cb;
      }
      img {
        min-width: 100%;
        @media (max-width:800px) {
          width: 100%;
        }
      }
      .content {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        padding: 25px;

        background: rgba(24, 28, 35, .75);

        @include transition($transition-default);
        .title {
          text-transform: uppercase;

          color: #6095cb;

          font-weight: 700;
        }
        p {
          margin: 0;

          color: #eef9fe;

          font-size: 15px;
        }
      }
      &:hover {
        .content {
          padding-bottom: 50px;
        }
      }
    }
    .side-news {
      display: table;

      width: 100%;
      margin-bottom: 30px;

      text-decoration: none;
      @media (max-width:800px) {
        margin-bottom: 10px;
      }
      @media (max-width:400px) {
        display: block;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .content {
        position: relative;

        display: table-cell;
        overflow: hidden;

        width: 50%;
        padding: 0 20px;

        vertical-align: middle;

        border: 15px solid #7c8c5f;
        background: #fff;

        @include transition($transition-default);
        @media (max-width:550px) {
          padding: 0 10px;

          border: 5px solid #7c8c5f;
        }
        @media (max-width:400px) {
          display: block;

          width: 100%;
          padding: 10px;
        }
        &:before {
          position: absolute;
          top: -50px;
          right: 10px;

          display: block;

          width: 38px;
          height: 39px;

          content: "";

          background: url($image+"side-news-icon.png");

          @include transition($transition-default);
        }
        .title {
          margin-right: 30px;

          text-transform: uppercase;

          color: #6095cb;

          font-weight: 700;
        }
        p {
          margin: 0;

          color: #494949;

          font-size: 15px;

          @include transition($transition-default);
        }
      }
      .img-block {
        position: relative;

        display: table-cell;
        overflow: hidden;

        width: 50%;

        vertical-align: middle;

        background: #7c8c5f;
        @media (max-width:400px) {
          display: block;

          width: 100%;
        }
        img {
          width: 100%;
        }
        &:after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          content: "";

          opacity: 0;
          background: rgba(83, 96, 61, .8);

          @include transition($transition-default);
        }
      }
      &:hover {
        .content {
          background: #53603d;
          &:before {
            top: 10px;
          }
          p {
            color: #fff;
          }
        }
        .img-block {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
  .more {
    margin: 30px auto 0;

    color: #fff;
    background: #6095cb;
  }
}
