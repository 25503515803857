.residents-list {
  width: 100%;

  background: #d8ebfe;
  @media (max-width:600px) {
    display: block;
  }
  tbody {
    @media (max-width:600px) {
      display: block;
    }
  }
  tr {
    @media (max-width:600px) {
      display: block;
    }
    td {
      width: 50%;

      border: 2px solid #fff;
      @media (max-width:600px) {
        display: block;

        width: 100%;

        border: none;
        border-bottom: 2px solid #fff;
      }
      .insde {
        max-width: 450px;
        padding: 40px 10px 40px 40px;
        @media (max-width:600px) {
          max-width: 100%;
          padding: 10px;
        }
      }
      .title {
        margin-bottom: 10px;

        text-transform: uppercase;

        color: #376ea6;

        font-size: 30px;
        font-weight: 700;
        line-height: 1.2;
        @media (max-width:820px) {
          font-size: 20px;
        }
      }
      p {
        color: #4c4c4c;

        font-size: 25px;

        font-weight: 700;
        @media (max-width:820px) {
          font-size: 16px;
        }
      }
      .more {
        margin-top: 20px;

        text-transform: uppercase;

        color: #fff;

        background: #6095cb;
      }
      &:first-child {
        .insde {
          margin: 0 0 0 auto;
          padding: 40px 40px 40px 10px;
          @media (max-width:600px) {
            max-width: 100%;
            padding: 10px;
          }
        }
      }
    }
  }
}
