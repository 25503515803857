.subscribe {
  margin: 2px 0;
  padding: 20px 10px;

  text-align: center;

  background: #6095cb;
  img {
    @include inline-block;
    @media (max-width:900px) {
      display: none;
    }
  }
  .text {
    margin: 0 70px 0 15px;

    text-align: left;

    line-height: 1;

    @include inline-block;
    @media (max-width:900px) {
      margin: 0;

      text-align: center;
    }
    .title {
      margin-bottom: 5px;

      text-transform: uppercase;

      color: #fff;

      font-size: 26px;
      font-weight: 700;
    }
    p {
      margin: 0;

      color: #393939;
    }
  }

  #mc-embedded-subscribe-form {
    @include inline-block;
    @media (max-width:900px) {
      margin-top: 10px;
    }
    input {
      float: left;

      width: 200px;
      height: 40px;
      padding: 0 10px;

      border: #fff;
      background: #fff;
      @media (max-width:400px) {
        width: 100%;
        float: none;
      }
    }
    button {
      float: left;

      min-width: 140px;
      height: 40px;
      padding: 0 15px;

      text-align: center;
      text-decoration: none;

      color: #fff;
      border: none;
      background: #3c6186;

      @include transition($transition-default);
      @media (max-width:400px) {
        width: 100%;
        float: none;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
}
