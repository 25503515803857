.police-social {
  overflow: hidden;

  text-align: center;
  @media (max-width:700px) {
    clear: both;

    padding-top: 15px;
  }
  a {
    width: 45px;
    height: 45px;
    margin: 0 3px;

    text-decoration: none;

    color: #fff;

    font-size: 30px;
    line-height: 45px;

    @include inline-block;
    @include transition($transition-default);
    @media (max-width:350px) {
      width: 40px;
      height: 40px;

      line-height: 40px;
    }
    &.fa-facebook {
      background: #3a5897;
    }
    &.fa-twitter {
      background: #49c9f2;
    }
    &.fa-instagram {
      background: #3b749f;
    }
    &.fa-pinterest-p {
      background: #cd2129;
    }
    &.fa-linkedin {
      background: #0775b7;
    }
    &.fa-youtube {
      background: #ce332c;
    }
    &:hover {
      opacity: .8;
    }
  }
}
