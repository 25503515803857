.four {
  overflow: hidden;

  margin: 0 -1px;
  .item {
    float: left;

    width: (100%/4);
    padding: 0 1px;
    @media (max-width:1000px) {
      width: (100%/2);

      border-top: 1px solid #fff;
    }
    @media (max-width:550px) {
      width: 100%;
    }
    .inside {
      position: relative;

      display: block;

      padding: 20px 10px;

      text-align: center;
      text-decoration: none;

      background: #5f6a4b;

      @include transition($transition-default);
      .icon {
        width: 75px;
        height: 75px;
        margin: 0 auto 10px;

        @include transition($transition-default);
      }
      .text {
        text-transform: uppercase;

        color: #ffffff;

        font-size: 30px;

        @include transition($transition-default);
      }
      &:hover {
        background: #85946a;
        .icon {
          background-position: bottom center;
        }
        .text {
          color: #343435;
        }
      }
    }
    &:nth-child(1) {
      .icon {
        background: url($image+"four-icon-1.png");
      }
    }
    &:nth-child(2) {
      .icon {
        background: url($image+"four-icon-2.png");
      }
    }
    &:nth-child(3) {
      .icon {
        background: url($image+"four-icon-3.png");
      }
    }
    &:nth-child(4) {
      .icon {
        background: url($image+"four-icon-4.png");
      }
    }
  }
}
