// @import 'compass';

@mixin clear {
  &:after {
    content: '';

    display: block;
    clear: both;
  }
}
@mixin block-center {
  position: relative;

  max-width: 1200px;
  margin: 0 auto;
  padding-right: 10px;
  padding-left: 10px;

  @include clear;
}
.clear {
  &:after {
    content: '';

    display: block;
    clear: both;
  }
}
.block-center {
  position: relative;

  max-width: 1200px;
  margin: 0 auto;
  padding-right: 10px;
  padding-left: 10px;

  @include clear;
}
.content-block {
  overflow: hidden;
}

@mixin stickyFooter($footerHeight, $wrapSelector, $footerSelector) {
  html, body {
    height: 100%;
  }

  #{$wrapSelector} {
    min-height: 100%;
    &:after {
      content: '';

      display: block;
    }
  }

  #{$wrapSelector}:after {
    height: $footerHeight;
  }

  #{$footerSelector} {
    margin-top: -$footerHeight;
    min-height: $footerHeight;
  }
}

@font-face {
    font-family: 'Master Of Break';
    src: url('../fonts/MasterOfBreak/master_of_break.eot');
    src: url('../fonts/MasterOfBreak/master_of_break.eot') format('embedded-opentype'),
         url('../fonts/MasterOfBreak/master_of_break.woff2') format('woff2'),
         url('../fonts/MasterOfBreak/master_of_break.woff') format('woff'),
         url('../fonts/MasterOfBreak/master_of_break.ttf') format('truetype'),
         url('../fonts/MasterOfBreak/master_of_break.svg#master_of_break') format('svg');
}
$transition-default: all 300ms ease;
$awesome: 'FontAwesome';

$raleway: 'Raleway', sans-serif;
$roboto: 'Roboto', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$opensans: 'Open Sans', sans-serif;
$master: 'Master Of Break', sans-serif;
