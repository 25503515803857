.sh-village-history {
  margin-bottom: 20px;

  padding: 35px 0;

  text-align: center;

  background: #525d3c url($image+"sh-village-history.jpg") left center no-repeat;
  background-size: auto 100%;
  .block-center {
    max-width: 930px;
  }
  h2 {
    color: #ffffff;
  }
  p {
    color: #ebf0e0;

    font-weight: 700;
  }
  .more {
    margin: 30px auto 0;

    color: #ffffff;
    background: #6095cb;
  }
}
